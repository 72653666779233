.form-group {
  margin-bottom: 12px;

  label {
    display: block;
    margin-bottom: 6px;
    color: #666;

    &.inline {
      display: inline;
    }
  }

  [type='text'],
  [type='password'],
  [type='email'],
  [type='tel'],
  [type='search'],
  select,
  textarea {
    width: 100%;
    border: 1px solid color(border);
    padding: 4px 6px;
    border-radius: $site-border-radius;
    outline: none;

    &:focus {
      border-color: palette(blue);
    }

    &:disabled {
      background-color: #eee;
    }

    &[readonly] {
      background-color: #eee;
    }
  }

  [type='tel'] {
    width: 116px;
  }

  select {
    appearance: none;
    background: url('/assets/images/person-light.svg') no-repeat right center;
    background-size: 23px;
  }

  option[value=''] {
    color: fontColor(inactive);
    font-style: italic;
  }

  textarea {
    min-height: 66px;
  }

  .information {
    margin-top: 0;
    color: #888;
    font-style: italic;
  }
}

.button-group {
  display: flex;
  margin-bottom: calc($site-spacing-unit / 2);
  align-items: baseline;

  .button {
    margin-left: calc($site-spacing-unit / 2);
  }
}

.button-group--right {
  justify-content: flex-end;
}

.button-group--center {
  justify-content: center;
}

.required-field-legend {
  color: palette(orange);
  font-style: italic;
}

.required-field-marker::after {
  content: '*';
  color: palette(orange);
  padding-left: calc($site-spacing-unit / 8);
  padding-right: calc($site-spacing-unit / 8);
}

.url-list {
  textarea {
    height: 66px;
  }
}

.app-filters {
  > div {
    margin-right: calc($site-spacing-unit / 4);
  }

  .Select {
    &.is-focused {
      transition: width 0.3s;
      width: 350px;
    }
  }
}

.Select {
  transition: width 0.3s;
  min-width: 200px;

  .Select-control {
    border-radius: 2px;
    border-color: #d9d9d9;
    height: 32px;

    .Select-placeholder {
      line-height: 30px;
      font-size: 13px;
    }
  }

  .Select-arrow {
    border-color: #808080 transparent transparent;
  }

  .Select-input {
    height: 30px;
    line-height: 30px;
  }

  .Select-menu-outer {
    font-size: 13px;
  }

  .Select-clear {
    line-height: 22px;
  }
}

.Select--single {
  .Select-control {
    .Select-value {
      line-height: 30px;
    }
  }
}

.Select--multi {
  .Select-value {
    line-height: 16px;
  }

  .Select-value-icon {
    padding-bottom: 1px;
  }
}

.a-form {
  display: flex;
  padding: 16px 0 0 0;
  margin-bottom: 16px;

  &.vertical {
    flex-direction: column;
  }

  .a-form--description {
    width: 300px;
    min-width: 300px;
    margin-right: $site-spacing-unit;

    h2 {
      font-weight: fontWeight(semi-bold);
      margin-bottom: $site-spacing-unit;
    }
  }

  .a-form--content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;

    &.vertical {
      flex-direction: column;
    }
  }

  .a-form--row {
    display: flex;
    justify-content: flex-start;
  }

  .a-form--row > * {
    flex: 1;
  }

  .a-form--col {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    justify-content: flex-start;
  }

  .a-label {
    color: palette(gray, dark);
    display: inline;
    font-weight: fontWeight(semi-bold);
    margin-bottom: calc($site-spacing-unit / 4);
    position: relative;

    .required {
      padding-left: 2px;
      color: palette(red);
      right: 2px;
      top: 2px;
    }
  }

  .a-field {
    padding: calc($site-spacing-unit / 2);
    min-width: 30%;

    .a-input {
      // BHTODO: review this rule
      border: 1px solid color(border);
      outline: none;
      padding: calc($site-spacing-unit / 2) calc($site-spacing-unit / 4);
      width: 100%;

      &:focus {
        border-color: palette(blue);
      }

      &[readonly] {
        background-color: palette(gray, xxlight);
      }
    }

    .a-textarea {
      min-height: calc($site-spacing-unit * 4);
    }

    .a-button-wrapper {
      width: 100%;
    }
  }

  .a-form--dropzone {
    .preview-link {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .dropzone {
      cursor: pointer;
      align-items: center;
      border: 2px dashed palette(gray, light);
      border-radius: 0.5rem;
      font-size: 18px;
      padding: $site-spacing-unit;
      background-color: palette(gray, xxlight);
      transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out;

      i {
        color: #91c2ea;
        font-size: 40px;
      }

      &.disabled {
        cursor: auto;
        opacity: 0.5;
      }

      &:hover {
        background-color: palette(blue, xxlight);
        border: 2px dashed palette(blue, light);
      }
    }
  }
}

.a-field--image {
  position: relative;
  width: 300px;
  height: 300px;
  padding: calc($site-spacing-unit / 2);
  margin-bottom: calc($site-spacing-unit / 4);

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.a-field--remove {
  background-color: palette(red);
  color: white;
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 20px;

  &:hover {
    background-color: palette(red, dark);
  }

  &:active {
    background-color: palette(red, xdark);
  }
}

.dropzone-image-preview {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
