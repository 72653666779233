.terms-and-conditions {
  margin: calc($site-spacing-unit * 4) auto;
  width: $site-width;
  background-color: palette(white);
  padding: 32px;
  border: 1px solid #ddd;
  border-radius: 2px;

  .button-decline {
    padding-left: 1em;
    margin-left: 1em;
  }

  .button-primary {
    margin-left: 1em;
  }

  .terms-url-link {
    margin-left: 1em;
  }

  ol {
    counter-reset: item
  }

  li {
    display: block;
    margin: 12px 0;

    &:before {
      content: counters(item, ".") " ";
      counter-increment: item
    }

    > span {
      margin-left: 20px;
    }
  }

  p {
    margin-left: 30px;
  }


  .nested {
    li {
      text-indent: -42px;
      margin-left: 32px;
    }
  }

  .terms {
    border: 1px solid #ccc;
    height: 600px;
    overflow: scroll;
    padding: 32px;
    margin-bottom: 32px;
  }
}
