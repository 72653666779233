.new-developer-app {
  padding-left: 20%;
  padding-right: 20%;

  .new-developer-app-header {
    text-align: center;
    margin-top: -24px;
  }

  .form-group label {
    color: palette(gray, xdark);
  }

  .form-group textarea {
    resize: vertical;
  }

  .information {
    color: palette(gray, base);
  }
}
