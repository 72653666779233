.invitation {
  text-align: center;
  background-color: palette(white);
  padding: calc($site-spacing-unit / 2) calc($site-spacing-unit * 2);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin: calc($site-spacing-unit * 2) auto;
  width: calc($site-width / 2);

  .button-group {
    margin-top: calc($site-spacing-unit * 2);
  }
}
