.media-block-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  align-content: center;
  max-width: 1068px;
  .media-block {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 300px;
    padding: 12px;
    min-width: 256px;
    h1 {
      font-size: 18px;
    }
    .media-block-icon {
      height: 46px;
      width: 46px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      img {
      }
    }
  }
}
