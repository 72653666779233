.endpoint-group-title {
  display: flex;
  border-width: 1px 1px 1px 4px;
  border-radius: 4px;
  border-color: palette(gray, xlight);
  border-style: solid;
  margin: space(md) space(xl) 0 space(xl);
  padding: space(sm) 0 0 space(sm);
}

.endpoint {
  margin: space(xl);
  margin-bottom: calc(space(xl) * 3);

  &:first-of-type {
    margin-top: space(md);
  }
}

.endpoint-header {
  text-transform: capitalize;
  margin-bottom: 0;
}

.endpoint-block {
  margin-top: space(md);
  margin-bottom: space(md);
}

.pagination-header {
  margin-bottom: space(md);
}

.divider {
  height:3px;
  background-color:#464646;
  margin-bottom:3px;
}

.deprecation-banner-top {
  margin: 12px 48px 48px;

  p {
    margin-bottom: 6px;
  }
}

.endpoint-request {
  font-family: fontFamily(code);
  word-wrap: break-word;
  padding: space(sm);

  border-radius: 4px;
  border-left: 8px solid transparent;
  border-right: 1px solid palette(gray, xlight);
  border-top: 1px solid palette(gray, xlight);
  border-bottom: 1px solid palette(gray, xlight);

  &.endpoint-request--get {
    border-left-color: color(http-get);
  }
  &.endpoint-request--post {
    border-left-color: color(http-post);
  }
  &.endpoint-request--patch {
    border-left-color: color(http-patch);
  }
  &.endpoint-request--put {
    border-left-color: color(http-put);
  }
  &.endpoint-request--delete {
    border-left-color: color(http-delete);
  }
}

.changelog-block {
  position: relative;

  .endpoint-changelog-header {
    background-color: #f0f6fe;
    color: #000;
    font-weight: normal;

    .endpoint-changelog-header-content {
      margin: 5px;
    }
  }

  .changelog-table {
    box-shadow: none;
    border: 1px solid #dfdfe0;
    border-bottom: 0;
    thead th {
      font-weight: 600;
      font-size: 14px;
      padding: 12px;
    }
    tbody tr {
      border-right: 1px solid rgba(255, 255, 255, 0);
      border-left: 1px solid rgba(255, 255, 255, 0);
      &:hover {
        outline: 1px solid #2066df;
        cursor: pointer;
      }
      &:last-child {
        border-bottom: 1px solid #dfdfe0;
      }
    }

    tbody tr td > div {
      margin: 11px 9px;
    }
  }

  .show-more-button {
    outline: none;
    position: absolute;
    right: -3px;
    top: -10px;
  }
}

.changelog-modal-description {
  padding: 18px 0 0 0;
}

.changelog-modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    text-align: center;
    margin-bottom: 5px;
  }
}

.changelog-table,
.changelog-modal-body,
.changelog-col-right {
  .endpoint-changelog-token {
    margin-right: 5px;

    span {
      font-weight: bold;
      text-transform: uppercase;
    }

    &.token-breaking,
    &.token-deprecated {
      background-color: rgb(252, 227, 228);
      border: 1px solid rgb(250, 209, 210);
      span {
        color: rgb(230, 25, 32);
      }
    }
    &.token-sunset {
      background-color: rgb(238,240,241);
      border: 1px solid rgb(217, 222, 223);
      span {
        color: rgb(62, 72, 78);
      }
    }
    &.token-fix {
      background-color: rgb(255, 250, 224);
      border: 1px solid rgb(255, 246, 204);
      span {
        color: rgb(153, 48, 0);
      }
    }
    &.token-feature {
      background-color: rgb(228, 237, 251);
      border: 1px solid rgb(210, 224, 249);
      span {
        color: rgb(32, 102, 223);
      }
    }
    &.token-refinement,
    &.token-enhancement {
      background-color: rgb(255, 234, 224);
      border: 1px solid rgb(255, 220, 204);
      span {
        color: rgb(255, 81, 0);
      }
    }
    &.token-new {
      background-color: rgb(230, 250, 229);
      border: 1px solid rgb(213, 247, 212);
      span {
        color: rgb(28, 130, 23);
      }
    }
  }
}

#aggregated-changelog {
  display: flex;
  .changelog-modal-body {
    min-width: 500px;

    p {
      text-align: center;
      margin-bottom: 16px;
    }
  }

  .modal-secondary {
    color: #6a767c;
    font-size: 12px;
    margin-bottom: 0;
  }

  .doc-column-sidebar {
    width: 20%;
  }

  .aggregated-block {
    margin: 24px 30px 30px 50px; /*320px*/
    min-width: 60%;
    max-width: 60%;

    .aggregated-card {
      margin-bottom: 24px;
      padding: 40px 48px;
      width: 100%;
      position: relative;

      &.button-card {
        padding: 40px 48px 60px;
        height: 320px;

        .aggregated-content {
          overflow: hidden;
          height: 200px;
        }
        &.expanded,
        .expanded {
          height: auto;
        }
      }
    }
    .endpoint-request {
      padding: 4px;
      margin: 0;
      margin-bottom: 19px;
    }
    .changelog-modal-description {
      padding: 0;
    }
    .aggregated-header {
      margin-bottom: 20px;
    }
    .aggregated-card-body {
      justify-content: left;

      div {
        margin-right: 30px;
      }
      .endpoint-changelog-token {
        margin-right: 12px;
      }
    }
    .aggregated-show-toggle {
      position: absolute;
      bottom: 25px;
      right: 47px;
      cursor: pointer;

      &:hover {
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .aggregated-column-sidebar {
    width: 260px;
    min-width: 260px;
    background-color: #f9f9f9;
    border-right: 1px solid #dfdfe0;
    position: relative;
    min-height: 100vh;
    // top: 0;
    // z-index: -1;

    .aggregated-search {
      background-color: #fff;
      border-bottom: 1px solid #dfdfe0;
      height: 40px;
      width: 100%;
    }
    .aggregated-filter {
      padding: 32px 24px 120px 36px;
    }
    .aggregated-checkbox {
      display: block;
      line-height: 1;
      position: relative;
      margin-left: 17px;
      &.primary {
        margin-left: 0;
        color: #232729;
        font-weight: bold;
      }

      input {
        width: 24px;
        height: 17px;
        position: relative;
        top: 3px;
        margin-right: 7px;
      }
    }
  }
}

.change-log-agg-token {
  margin: 1rem 0;
}
