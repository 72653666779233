.app-listing, .faq-listing {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    background-color: #fff;
    padding: 12px;
    font-size: 12px;
    margin: 8px 0;
    font-size: 16px;
    box-shadow: 0 0px 2px rgba(0,0,0,.25);
    border-radius: 2px;
  }
}
