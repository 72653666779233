.doc-column-sidebar {
  width: 260px;
  min-width: 260px;
}

.doc-column-sidebar--slim {
  width: 240px;
  min-width: 240px;
}

.doc-column-sidebar-left-style {
  background-color: palette(gray, xxlight);
  border-right: 1px solid palette(gray, xlight);
}

.doc-nav-left-options {
  padding-bottom: calc($site-spacing-unit * 5);
  padding-left: calc($site-spacing-unit * 1.5);
  padding-right: $site-spacing-unit;
}

.doc-nav-right-options {
  padding-top: space(lg);
  padding-bottom: calc($site-spacing-unit * 5);
  padding-right: space(lg);
}

.documentation-container {
  background-color: palette(white);
  display: flex;

  .documentation-body {
    width: 100%;
    margin: 0 $site-spacing-unit;
    overflow-x: auto;

    @include for-desktop-up {
      margin: 0 calc($site-spacing-unit * 2);
    }
  }
}

.documentation-style-scope {
  h1, h2, h3, h4, h5, h6, {
    margin-top: calc($site-spacing-unit * 1.5);
  }

  p, li {
    color: palette(gray);
  }

  img {
    max-width: 100%;
  }

  hr {
    border: 1px solid color(border);
  }
}
