html,
body {
  -webkit-font-smoothing: antialiased;
  color: fontColor(primary);
  font-family: fontFamily(primary);
  font-size: fontSize(normal);
}

h1, h2, h3, h4, h5, h6, p, pre, blockquote {
  margin-top: 0;
  margin-bottom: 16px;
}

h1 {
  font-size: 28px;
  font-weight: fontWeight(light);
  color: fontColor(primary);
}

h2 {
  font-size: 18px;
  font-weight: fontWeight(normal);
  color: palette(gray, dark);
}

h3 {
  font-size: 16px;
  font-weight: fontWeight(normal);
  color: palette(gray, xdark);
}

h4 {
  font-size: 14px;
  font-weight: fontWeight(light);
  color: palette(gray, xdark);
}

p {
  font-size: 14px;
  line-height: 22px;
}

b, strong {
  color: palette(gray, dark);
  font-weight: fontWeight(bold);
}

ul, ol {
  padding-left: calc($site-spacing-unit * 1.5);
  margin: calc($site-spacing-unit / 2) 0;
}

.inactive {
  color: fontColor(inactive);
  font-size: fontSize(small);
}

.information {
  font-size: fontSize(small);
}

.whisper {
  font-size: fontSize(small);
  font-style: italic;
}

.font-semi-bold {
  font-weight: fontWeight(semi-bold);
}

.link-list {
  margin: 0 0 $site-spacing-unit 0;
  padding: 0;
  list-style: none;

  > li {
    margin-bottom: calc($site-spacing-unit / 4);
  }
}

code {
  font-family: fontFamily(code);
  white-space: pre-wrap;
}

.markdown {
  code {
    display: block;
    white-space: normal;
    margin: calc($site-spacing-unit / 2) 0;
    background-color: #f5f5f5;
    border-radius: $site-border-radius;
    border-left: 4px solid #ddd;
    padding: calc($site-spacing-unit / 2);
  }
}

.pct-code {
  @include code();
}

.pct-code-block {
  @include code();
  display: block;
  padding: calc($site-spacing-unit / 2);
}

div.pct-code {
  padding: calc($site-spacing-unit / 2);
}

.pre-wrap {
  white-space: pre-wrap;
}

.markdown-scope {
  // block code samples
  pre {
    @include code();
    padding: calc($site-spacing-unit / 2);
  }
  // inline code samples
  code:not(.language-json) {
    @include code();
  }
}

.highlight {
  //background-color: #242e3e;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: $site-border-radius;
  //color: #ccc;
  color: $font-color;
  overflow: auto;
  padding: 16px;
  width: 100%;

  .s2 {
    color: palette(orange);
  }

  .nn {
    color: palette(orange);
  }

  .mi {
    //color: #FDD857;
    //color: $blue-1;
  }
}

.highlight-error {
  @extend .highlight;
  background-color: palette(red, xlight);
}

.highlight-warning {
  @extend .highlight;
  background-color: palette(yellow, xlight);
}

.marketing-content h1,h2,h3,h4,h5,h6 {
  font-family: fontFamily(marketing);
}

.main-content--full-screen {
  h1 {
    margin: 0;
  }

  h2 {
    margin: 0;
  }
}
