.overflow-x-auto {
  overflow-x: auto;
}

@each $size, $value in $space {
  .margin-#{$size}   { margin: $value; }
  .margin-t-#{$size} { margin-top: $value; }
  .margin-r-#{$size} { margin-right: $value; }
  .margin-b-#{$size} { margin-bottom: $value; }
  .margin-l-#{$size} { margin-left: $value; }
  .margin-x-#{$size} { margin-left: $value; margin-right: $value; }
  .margin-y-#{$size} { margin-top: $value;  margin-bottom: $value; }
}

.hidden {
  display: none;
}

.putil-relative {
  position: relative;
}
.putil-absolute {
  position: absolute;
}
.putil-top {
  top: 0;
}
.putil-middle {
  top: 50%;
  transform: translateY(-50%);
}
.putil-bottom {
  bottom: 0;
}
.putil-left {
  left: 0;
}
.putil-center {
  left: 50%;
  transform: translateX(-50%);
}
.putil-right {
  right: 0;
}
.putil-middle.putil-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.text-right  { text-align: right; }
.text-center { text-align: center; }

.display-inline { display: inline; }
.float-right { float: right; }
