.kpi-title {
  color: palette(gray, light);
}

.kpi-value {
  @extend .kpi-title;
  font-size: 400%;
}

.kpi-flex {
  height: 48%;
}

.kpi-box {
  @extend .kpi-flex;
}
