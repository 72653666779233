$site-spacing-unit: 24px;

$space: (
  xxs: calc($site-spacing-unit / 8),
  xs: calc($site-spacing-unit / 4),
  sm: calc($site-spacing-unit / 2),
  md: $site-spacing-unit,
  lg: calc($site-spacing-unit * 1.5),
  xl: calc($site-spacing-unit * 2)
);

@function space($size: md) {
  @if map-has-key($space, $size) {
    @return map-get($space, $size);
  }

  @warn "The key #{$size} is not in the map $space";
  @return null;
}
