.verb-tag {
  display: inline-block;
  width: 42px;
  min-width: 42px;
  line-height: 14px;
  font-size: 9px;
  text-align: center;
  text-transform: uppercase;
  color: palette(white);
  background-color: color(muted);
  border: 1px solid color(muted);
  border-radius: 4px;

  &--get {
    background-color: color(http-get);
    border-color: color(http-get);
  }
  &--post {
    background-color: color(http-post);
    border-color: color(http-post);
  }
  &--put {
    background-color: color(http-put);
    border-color: color(http-put);
  }
  &--patch {
    background-color: color(http-patch);
    border-color: color(http-patch);
  }
  &--delete {
    background-color: color(http-delete);
    border-color: color(http-delete);
  }
}

div.endpoint-stage-tag {
  margin-left: 12px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }
  &--pilot {
    background-color: rgb(255, 250, 224);
    border: 1px solid rgb(255, 246, 204);
    span {
      color: rgb(153, 48, 0);
    }
  }
  &--beta {
    background-color: rgb(228, 237, 251);
    border: 1px solid rgb(210, 224, 249);
    span {
      color: rgb(32, 102, 223);
    }
  }
}

div.endpoint-dep-tag {
  margin-left: 12px;
  background-color: rgb(252, 227, 228);
  border: 1px solid rgb(250, 209, 210);

  span {
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(230, 25, 32);
  }
}

.popover-tag {
  display: inline;
  text-align: center;
  background-color: palette(white);
  border: solid palette(blue, light) 2px;
  border-radius: 33px;
  color: palette(blue, light);
  font-weight: fontWeight(bold);
  padding: 0 9px;
  margin-left: 5px;

  &:hover {
    background-color: palette(blue, light);
    transition: background-color 400ms ease-in-out;
    color: palette(white);
  }
}
