.call-to-action {
  display: flex;
  text-align: center;
  padding-bottom: 64px;
  h1 {
    text-transform: uppercase;
    margin: 24px;
  }
  .button {
    width: 124px;
    margin-right: 24px;
  }
}
