.callout {
 padding: calc($site-spacing-unit / 4)  $site-spacing-unit;
 margin: calc($site-spacing-unit / 2) 0;

 .callout-header {
   text-transform: uppercase;
 }

 &.callout-info {
   border-left: 2px solid palette(blue, dark);
   background-color: palette(blue, xxlight);

   .callout-header {
     color: palette(blue, dark);
   }
 }

 &.callout-warning {
   border-left: 2px solid palette(orange);
   background-color: palette(orange, xxlight);

   .callout-header {
     color: palette(orange);
   }
 }

 &.callout-danger {
   border-left: 2px solid palette(red, dark);
   background-color: palette(red, xxlight);

   .callout-header {
     color: palette(red, dark);
   }
 }
}
