.a-card {
  background-color: palette(white);
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}

.a-card-block {
  padding: $site-spacing-unit calc($site-spacing-unit * 2);
}

.a-card-block--line-after {
  border-bottom: 1px solid color(border);
}

.a-card-block--margin-after {
  margin-bottom: 50px;
}

.a-card--unread {
  background-color: palette(orange, xxlight);
}
