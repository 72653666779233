.stat-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 80px auto;

  .stat-block-stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    margin: 24px;
    font-size: 18px;
    line-height: 22px;
    width: 200px;
    min-width: 200px;
    height: 200px;
    border: 1px solid white;
    text-transform: uppercase;
    .stat-block-top {
      height: 15%;
    }
    .stat-block-bottom {
      height: 25%;
    }
    .stat-block-number {
      font-family: fontFamily(marketing);
      font-size: 75px;
      line-height: 90px;
      font-weight: fontWeight(semi-bold);
      letter-spacing: -1px;
    }
  }
}
