#manage-collaborators {
  max-width: 1200px;
  margin: 24px auto;
}

.collaborator-table {
  .collaborator-table__header {
    padding: calc($site-spacing-unit / 2) calc($site-spacing-unit * 2);
    border-top: 1px solid color(border);
    border-bottom: 1px solid color(border);
    display: flex;
    font-weight: fontWeight(semi-bold);

    >h3 {
      margin-top: 0;
    }
  }

  .collaborator-table__user-col { min-width: 50%; }
  .collaborator-table__role-col { text-align: center; min-width: 15%; }
  .collaborator-table__options-col {
    text-align: center; min-width: 5%;
    .dropdown-list {
      text-align: left;
    }
  }

  .collaborator-table__row {
    padding: calc($site-spacing-unit / 2) calc($site-spacing-unit * 2);
    border-bottom: 1px solid color(border);
    display: flex;
    align-items: center;
  }

  .collaborator-table__user-col__header {
    font-size: fontSize(large);
    color: palette(orange);
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .collaborator-table__user-col__secondary {
    font-size: fontSize(normal);
    color: palette(gray, xdark);
  }
}

.collaborator-table--modal {
  margin: $site-spacing-unit calc($site-spacing-unit * -2);

  .collaborator-table__header {
    background-color: palette(gray, xxlight);
  }
}
