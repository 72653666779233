$font-families: (
  code: (
    'Fira Mono',
    monospace,
  ),
  primary: (
    'Inter',
    sans-serif,
  ),
  marketing: (
    'Gotham A',
    'Gotham B',
    sans-serif,
  ),
);

@function fontFamily($family: primary) {
  @if map-has-key($font-families, $family) {
    @return map-get($font-families, $family);
  }

  @warn "The key #{$family} is not in the map $font-families";
  @return null;
}

$font-weights: (
  light: 300,
  normal: 400,
  semi-bold: 600,
  bold: 700,
);

@function fontWeight($weight: normal) {
  @if map-has-key($font-weights, $weight) {
    @return map-get($font-weights, $weight);
  }

  @warn "The key #{$weight} is not in the map $font-weights";
  @return null;
}

$font-sizes: (
  small: 12px,
  normal: 14px,
  large: 18px,
);

@function fontSize($size: normal) {
  @if map-has-key($font-sizes, $size) {
    @return map-get($font-sizes, $size);
  }

  @warn "The key #{$size} is not in the map $font-sizes";
  @return null;
}

$font-colors: (
  primary: palette(gray, xxdark),
  placeholder: palette(gray, light),
  inactive: palette(gray, light),
);

@function fontColor($color: primary) {
  @if map-has-key($font-colors, $color) {
    @return map-get($font-colors, $color);
  }

  @warn "The key #{$color} is not in the map $font-colors";
  @return null;
}

@mixin forceWordWrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

@mixin code() {
  background-color: #f5f5f5;
  border-radius: 2px;
  border: 1px solid #ddd;
  font-family: fontFamily(code);
  padding-left: 3px;
  padding-right: 3px;
  line-height: 16px;
}
