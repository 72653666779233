/*  MARKETO  */
#mktoStyleLoaded {
  color:#4d4d4d;
}

.mktoForm, .mktoForm .mktoHasWidth, label.mktoLabel.mktoHasWidth, .mktoForm .mktoField, .mktoForm .mktoTextField, .mktoForm .mktoHasWidth, .mktoForm.mktoHasWidth.mktoLayoutAbove, .lpeRegForm {
  width:100%!important;
  float:none!important;
}

.mktoForm input[type=text], .mktoForm input[type=url], .mktoForm input[type=email], .mktoForm input[type=tel], .mktoForm input[type=number], .mktoForm input[type=date] {
  line-height:normal;
}

.mktoForm .mktoFieldWrap, .mktoFieldWrap .mktoFromCol, .mktoFieldWrap .mktoRequiredField {
  width:100%!important;
  float:none!important;
}

.mktoForm .mktoFormCol {
  float:none!important;
}

.mktoOffset, .mktoForm.mktoLayoutAbove .mktoRequiredField .mktoAsterix, .mktoAsterix {
  display:none!important;
}

.mktoForm input[type=text], .mktoForm input[type=url], .mktoForm input[type=email], .mktoForm input[type=tel], .mktoForm input[type=number], .mktoForm input[type=date], .mktoForm textarea.mktoField, .mktoForm select.mktoField, .mktoField .mktoTextField {
  display:block;
  float:none!important;
  width:100%!important;
  height:30px;
  padding:5px 10px !important;
  font-size:16px;
  line-height:1.2 !important;
  color:#4d4d4d;
  background-color:#f0f0f0;
  background-image:none;
  border:none;
  border-radius:0 !important;
  margin-bottom:10px !important;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.mktoForm textarea[rows="2"] {
  height:60px !important;
}

.mktoFieldDescriptor .mktoFormCol {
  margin-bottom:0 !important;
  height:0 !important;
}

.mktoForm .mktoButtonWrap {
  display:block;
  float:none!important;
  width:100%!important;
  margin:auto!important;
  text-align:center;
}

.mktoForm input[type=text]:focus, .mktoForm input[type=url]:focus, .mktoForm input[type=email]:focus, .mktoForm input[type=tel]:focus, .mktoForm input[type=number]:focus, .mktoForm input[type=date]:focus, .mktoForm select.mktoField:focus, .mktoForm textarea.mktoField:focus {
  border-color:#F47E42;
  outline:0;
}

.mktoForm .mktoLabel, .mktoForm .mktoRequiredField label.mktoLabel, .mktField label {
  width:100%!important;
  font-weight:normal !important;
  font-family: sans-serif;
  font-weight:300;
  font-style:normal;
  font-size:13px;
  float:none!important;
  color:#4d4d4d;
  margin-bottom:5px;
  padding:0 !important;
  line-height:1.2 !important;
}

.mktoGutter {
  display:none !important;
}

.mktoForm .mktoButton {
  margin-top:15px !important;
}

.mktoImg {
  display:block;
  width:100%!important;
  max-width:100%!important;
  height:auto;
}

.mktoForm-white .mktoLayoutLeft {
  float:none !important;
  width:100%!important;
  margin:auto!important;
  text-align:center!important;
}


.mktLblLeft {
  margin:0;
  padding:0;
  list-style:none;
}


.mktoButton {
  @extend .button;
  @extend .button-primary;
}

.mktoButton:hover, .mktoButton:focus {
  @extend .button, :hover;
}

#confirm-form {
  display: none;
}
