html {
  height: 100%;
}

.sign-in-layout {
  background: url("/assets/images/signin-background.jpg");
  background-size: cover;
  height: 100%;
  min-height: 100%;
}

.sign-in {
  height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.signin-form {
  background-color: #fff;
  border-radius: $site-border-radius;
  border-radius: 5px;
  text-align: center;

  .signin-top {
    padding: $site-spacing-unit;
  }

  h2 {
    font-weight: fontWeight(normal);
    letter-spacing: 2px;
    margin-bottom: $site-spacing-unit;
  }

  .signup-links {
    border-top: 1px solid color(border);
    padding: $site-spacing-unit;
    color: palette(gray);
  }
}

.sign-in__extra-links {
  margin-top: calc($site-spacing-unit / 2);

  a {
    color: palette(white);
    font-size: fontSize(small);
    text-decoration: underline;
  }
}


.signup-banner {
  padding: calc($site-spacing-unit / 2);
  display: flex;
  background-color: rgba(0,0,0,0.25);
  align-items: center;

  .right {
    margin-left: auto;
    font-size: fontSize(large);
    color: palette(white);
    display: flex;
    align-items: center;

    .signin-link {
      margin-left: calc($site-spacing-unit / 2);
    }
  }
}

.connect-leaderboard {
  margin-top: calc($site-spacing-unit * 2);
  text-align: center;
  max-width: 700px;
  margin: calc($site-spacing-unit * 2) auto;

  h1 {
    margin-bottom: 12px;
    color: palette(white);
    font-weight: fontWeight(normal);
    text-shadow: 0px 1px #666;
    font-size: 32px;
  }

  h2 {
    text-shadow: 0px 1px #666;
    color: palette(white);
    font-weight: fontWeight(normal);
    text-transform: none;
  }
}

.signup-container {
  max-width: 700px;
  background-color: palette(white);
  padding: calc($site-spacing-unit * 2);
  margin: calc($site-spacing-unit * 2) auto;
  border-radius: 5px;

  .signup-errors {
    border-bottom: 2px solid palette(red);
    margin-bottom: 12px;

    p {
      color: palette(red);
    }

    ul {
      padding: 0;
    }
  }

  .g-recaptcha {
    display: flex;
    justify-content: center;
  }

  .password-requirements {
    color: palette(gray);
    padding-left: 0;
    list-style: none;
    font-style: italic;
  }
}
