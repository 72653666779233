.logo-block {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .logo-block-logo {
    padding: 10px 40px;

    @include for-phone-only() {
      transform: scale(0.8);
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
