.case-studies-container {
  max-width: $site-width;
  margin: calc($site-spacing-unit * 2) auto;
}

.case-studies-header {
  text-align: center;
}

.case-studies-grid {
  margin: calc($site-spacing-unit * 2) 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.case-study-card {
  width: 500px;
  padding: $site-spacing-unit;
  height: 300px;
  color: palette(white);
  text-shadow: 0px 1px 2px palette(gray, dark);
  position: relative;
  background: cover;
  border: 2px solid palette(black);
  overflow: hidden;
  margin: $site-spacing-unit;

  &:hover {
    border: 2px solid palette(orange);
    box-shadow: none;

    .case-study-card__image {
      transform: scale(1.1);
    }

    .case-study-card__mask {
      opacity: 0.55;
    }
  }
}

.case-study-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  transition: all 0.3s;
  height: 100%;
  z-index: -1;
}

.case-study-card__mask {
  background-color: palette(black);
  height: 100%;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: -1;
}

.case-study-card__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    margin-bottom: 0;
    color: palette(white);
  }

  p {
    margin-top: calc($site-spacing-unit / 4);
  }
}

.case-study-content {
  margin-left: auto;
  margin-right: auto;
  padding: calc($site-spacing-unit * 2) calc($site-spacing-unit * 0.5);
  max-width: calc($site-width / 1.5);

  p {
    margin-top: calc($site-spacing-unit * 0.5);
    margin-bottom: calc($site-spacing-unit * 0.5);
  }

  h2 {
    margin-top: $site-spacing-unit;
    font-size: 1.0em;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.case-study-quote {
  background-color: palette(gray, xxlight);
  padding: space(md);

  & + .case-study-content {
    padding-top: space(sm);
  }

  @include for-tablet-portrait-down() {
    padding-left: space(sm);
    padding-right: space(sm);
  }
}

.case-study-quote-content {
  max-width: $site-width;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  p {
    line-height: calc(fontSize(large) * 1.5);
    font-size: fontSize(large);
  }

  small {
    color: palette(gray);
  }
}
