/* Sequoia Palette */
$palette: (
  black: (
    base: #000
  ),
  white: (
    base: #fff
  ),
  orange: (
    xdark: #793E20,
    dark: #C36434,
    base: #F47E42,
    light: #F7A47B,
    xlight: #FACBB3,
    xxlight: #FDF2EC
  ),
  red: (
    xdark: #6C1C1D,
    dark: #AD2E30,
    base: #D93A3C,
    light: #E47576,
    xlight: #EFB0B1,
    xxlight: #FCF2F2
  ),
  gray: (
    xxdark: #242424,
    xdark: #3C3E40,
    dark: #57595D,
    base: #787A7D,
    light: #ABACAE,
    xlight: #DFDFE0,
    xxlight: #F9F9F9
  ),
  yellow: (
    xdark: #76601C,
    dark: #B1902A,
    base: #EDC139,
    light: #F2D374,
    xlight: #F7E6AF,
    xxlight: #FDF8EB
  ),
  green: (
    xdark: #0D604D,
    dark: #149074,
    base: #1BC19C,
    light: #8DE0CD,
    xlight: #D4F3EC,
    xxlight: #F7FDFB
  ),
  blue: (
    xdark: #033A66,
    dark: #05579A,
    base: #0875CE,
    light: #529EDC,
    xlight: #9CC7EB,
    xxlight: #F2F7FC
  ),
  pink: (
    base: #FB7272
  ),
  purple: (
    base: #9A8AC0
  )
);

@function palette($color: black, $tone: base) {
  @return map-get(map-get($palette, $color), $tone);
}

$colors: (
  muted: #aaa,
  notice: palette(yellow),
  success: palette(green),
  warning: palette(red),
  warning-dark: palette(red, dark),
  border: palette(gray, xlight),
  http-get: palette(green),
  http-post: palette(blue, light),
  http-patch: palette(yellow),
  http-put: palette(yellow),
  http-delete: palette(red, light)
);

@function color($adjective: primary) {
  @return map-get($colors, $adjective);
}

@mixin gradient($grad: orange) {
  @if $grad == orange {
    background-color: palette(orange);
    background-image: linear-gradient(to bottom right, palette(orange), palette(pink));
  }
}
