.info-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  & {
    padding-bottom: 0;
  }
  a {
    white-space: nowrap;
  }
  .info-panel-image {
    flex-grow: 1;
    order: 1;
    min-height: 490px;
    min-width: 400px;
    background-repeat: no-repeat;
    background-position: left center;
    margin-bottom: -40px;
    margin-top: -40px;
    object-fit: "contain";
    @media screen and (min-width: 600px) {
      // Hack fix for mobile safari
      flex-basis: 0;
    }
  }
  .info-panel-content {
    flex-grow: 1;
    order: 2;
    padding: 24px;
    min-width: 200px;
    max-width: 600px;
    color: palette(gray);
    margin: 40px;
    p {
      margin-bottom: 26px;
    }
    @media screen and (min-width: 600px) {
      // Hack fix for mobile safari
      flex-basis: 0;
    }
  }
}

.info-panel-flipped {
  @extend .info-panel;
  .info-panel-image {
    order: 2;
    background-position: left center;
  }
  .info-panel-content {
    order: 1;
  }
}
