.button {
  background-color: transparent;
  border-radius: $site-border-radius;
  border: 1px solid palette(orange);
  color: palette(orange);
  display: inline-flex;
  font-family: fontFamily(primary);
  font-size: 13px;
  font-weight: fontWeight(bold);
  justify-content: center;
  letter-spacing: 1px;
  line-height: 32px;
  margin: 2px;
  padding: 0 16px;
  text-transform: uppercase;

  &.button-primary {
    letter-spacing: 0.25px;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover:enabled {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }
}

.button-toggle-on {
  border: 1px solid #c3c2c3;
  color: #777777;
  padding: 2px 8px 2px 8px;
}

.button-toggle-off {
  background-color: #c3c2c3;
  border: 1px solid #c3c2c3;
  color: white;
  padding: 2px 8px 2px 8px;
}

.button-primary {
  @include gradient(orange);
  border-color: palette(orange);
  color: palette(white);

  &.button-primary--email {
    // Need !important in order to preserve
    // style when processed by premailer
    background-color: palette(orange) !important;
  }

  &:hover:enabled {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }

  &.button-primary--full-width {
    width: 100%;
  }
}

.button-outline {
  background-color: transparent;
  border-radius: $site-border-radius;
  border-color: palette(white);
  color: palette(white);

  &:hover {
    @include gradient(orange);
    border-color: palette(orange);
  }
}

.button-large {
  border-radius: $site-border-radius;
  display: block;
  font-size: 18px;
  padding: 14px 16px;
  width: 100%;
}

.button-medium {
  font-size: 16px;
  padding: 12px 28px;
  border-radius: $site-border-radius;
}

.button-secondary {
  @extend .button;
  background-color: #fff;
  font-weight: fontWeight(normal);
  font-size: 13px;
  line-height: 26px;
}

.button-information {
  color: palette(gray);
  border-color: color(border);
}

.button-icon {
  border: 1px solid $font-color;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px;
  color: $font-color;
}

.button-warning {
  color: palette(white);
  background-color: color(warning);
  border-color: color(warning);

  &:active {
    background-color: color(warning-dark);
    border-color: color(warning-dark);
  }
}
