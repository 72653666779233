/**
 * This stylesheet is intended for global-level styles.
 */
@import 'helpers/colors';
@import 'helpers/space';
@import 'helpers/typography';
@import 'helpers/responsive';
@import 'helpers/z-index';
@import 'variables';
@import 'normalize';
@import 'layout';
@import 'components/documentation-layout';

@import 'base/buttons';
@import 'base/forms';
@import 'base/links';
@import 'base/tables';
@import 'base/typography';

@import 'components/a-card';
@import 'components/action-blocks';
@import 'components/app-cards';
@import 'components/app-listing';
@import 'components/attribution-info';
@import 'components/branch-selector';
@import 'components/breadcrumbs';
@import 'components/callout';
@import 'components/call-to-action';
@import 'components/case-studies';
@import 'components/collaborator-table';
@import 'components/confirmation-email-sent';
@import 'components/dropdown';
@import 'components/endpoint';
@import 'components/flex-grid';
@import 'components/global-sitemap';
@import 'components/hero';
@import 'components/http-responses';
@import 'components/info-panel';
@import 'components/kpi';
@import 'components/logo-block';
@import 'components/marketo-form';
@import 'components/media-block';
@import 'components/modal';
@import 'components/nav-options';
@import 'components/new-developer-app';
@import 'components/notifications';
@import 'components/search-box';
@import 'components/sign-in';
@import 'components/stat-block';
@import 'components/static-nav';
@import 'components/tables';
@import 'components/tabs';
@import 'components/tags';
@import 'components/terms-and-conditions';
@import 'components/top-nav';
@import 'components/top-nav-notifications';

@import 'marketing';
@import 'invitations';

@import 'utils';
@import 'print';

.form-groups-in-tile {
  padding: calc($site-spacing-unit / 2);

  @include for-phone-only {
    padding: 0;
  }

  @include for-tablet-landscape-up {
    width: 50%;
  }
}

// Classes from https://www.npmjs.com/package/react-json-pretty
.json-pretty {
  @include code();
  padding: calc($site-spacing-unit / 2);
  color: palette(green, dark);

  .json-key {
    @include forceWordWrap();
    color: palette(gray, dark);
  }
  .json-value {
    @include forceWordWrap();
    color: palette(blue);
  }
  .json-string {
    @include forceWordWrap();
    color: palette(green, dark);
  }
  .json-boolean {
    color: palette(purple);
  }
}

.four-zero-four-layout {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.four-zero-four-title {
  color: palette(gray);
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 10px;
}

@keyframes copied-animation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.copied-text-message {
  animation: copied-animation 1.5s;
  background-color: palette(green);
  border-radius: 4px;
  color: palette(white);
  font-size: 12px;
  font-weight: fontWeight(semi-bold);
  padding: 3px 5px;
  z-index: $z-toast;
}

// admin notification preview
#notification-body-preview {
  width: 100%;
  border: 1px solid color(border);
  padding: 0px 6px;
  border-radius: $site-border-radius;
  outline: none;
  display: none;
}
