.dev-notification {
  color: palette(gray);
}

.dev-notification-timestamp {
  font-weight: fontWeight(light);
}

.dev-notification-read-more {
  color: palette(orange);
}

.dev-notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: space(sm);

  > h1,
  > h2 {
    margin-bottom: 0;
  }
}

.dev-notification-previewer {
  background: palette(gray, xlight);
}

.dev-notification-body img {
  max-width: 100%;
}
