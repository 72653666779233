a {
  color: palette(orange);
  text-decoration: none;

  &.default {
    color: inherit;
  }

  &.override-default {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    display: inline;
  }
}

a.small-to-action {
  font-size: 12px;
  color: palette(orange);
  font-weight: fontWeight(bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  &:hover {
    color: palette(orange, light);
  }
}

a.link__orange {
  color: palette(orange);
}
