// Colors
$font-color: #444;

// Site
$site-width: 1200px;
$site-spacing-unit: 24px;
$site-border-radius: 2px;
$body-bg-gray: hsl(200,8%,96%); // procore gray96
$legal-footer-height: 45px;

// Global Nav
$top-nav-bg-color: palette(white);
$top-nav-bg-color-alt: palette(gray, xdark);
$top-nav-font-color: #464646;
$top-nav-font-color-alt: palette(gray, light);
$top-nav-active-font-color: palette(black);
$top-nav-active-font-color-alt: palette(white);
$top-nav-height: 60px;
$top-nav-spacing-unit: 24px;
$top-nav-font-size: 12px;

// Z-Index
$z-base: 1;
$z-dropdown: 1000;
$z-toast: 3000;
