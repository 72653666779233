.attribution-info-container {
  position: relative;
  display: inline-block;
  line-height: normal;

  .attribution-info-content {
    left: 0;
    background-color: white;
    padding: 0 8px;
    z-index: 999;
    min-width: 150px;
    display: flex;
    flex-direction: row;

    .attribution-info-row {
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-bottom: 5px;

      .attribution-info-label {
        font-weight: bold;
        font-size: 12px;
        margin-right: 5px;
      }

      .attribution-info-value {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }

  .attribution-info-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}
