.hero {
  @extend .flex-center;
  color: palette(white);
  min-height: 640px;
  text-align: center;
  background-size: cover;
  position: relative;
  padding: $site-spacing-unit;

  &.hero--thin {
    height: 320px;
  }

  .hero-content {
    z-index: 100;
    @include for-tablet-landscape-up() {
      max-width: 60%;
    }
  }

  h1 {
    font-size: 44px;
    font-weight: 300;
    text-transform: uppercase;
    color: palette(white);
  }

  p {
    font-size: 18px;
  }

  .hero-actions {
    margin-top: 48px;
    .button {
      background-color: transparent;
      color: palette(white);
      min-width: 124px;
      margin-right: 24px;
    }
  }

  .hero__mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: palette(black);
    opacity: 0.65;
    z-index: 1;
  }
}
