.full-height-scroll-box,
.full-height-box {
  position: static;
  width: inherit;
  height: 100vh;
}

.full-height-scroll-box {
  overflow-y: auto;
}

.main-content {
  margin-left: auto;
  margin-right: auto;
  max-width: $site-width;
  min-height: calc(100vh - 106px);
}

.main-content--full {
  .main-content {
    max-width: 100%;
    width: calc(100vw - 4px);
  }
}

.main-content--full-screen {
  width: 100vw;
  .main-content {
    width: 100%;
    min-height: 100%;
    max-width: 100%;
    margin: 0;
  }
}

.main-content-section {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  h1 {
    font-size: 32px;
  }
  &.main-content-section-top {
    padding-top: 0;
  }
}

.main-content-section-alt {
  @extend .main-content-section;
  background-color: palette(gray, xxlight);
}

.body-class-gray {
  background-color: $body-bg-gray;
  width: calc(100vw - 15px);
}

.two-column-form {
  display: flex;

  .column-one,
  .column-two {
    width: 48%;
    min-width: 48%;
  }

  .column-one {
    flex-grow: 1;
    margin-right: 4%;
  }
}

.flash-area {
  margin: 0 0 $site-spacing-unit 0;

  .alert {
    text-align: center;
    padding: 8px 48px;
    background-color: palette(white);
    width: 100%;
    font-weight: 700;

    &.alert-success {
      background-color: palette(green);
      color: palette(white);
    }

    &.alert-warning {
      background-color: palette(yellow);
      color: palette(gray, dark);
    }

    &.alert-error {
      background-color: palette(red);
      color: palette(white);
    }
  }
}

body {
  .full-screen {
    height: calc(100% - 105px);

    .page-body {
      flex: 1 0 0;
      overflow: auto;
      height: calc(100vh - 105px);
      z-index: $z-base;
    }
  }
}

.alert-notice {
  padding: 12px;
  font-size: 16px;
  background-color: #fff;
  margin: 12px auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  border-left: 5px solid color(notice);

  .fa {
    color: color(notice);
    margin-right: 8px;
    line-height: 18px;
  }
}

.error_explanation {
  padding: 4px 6px;
  color: palette(red);
  font-weight: bold;
  margin-top: 12px;
  border: 1px solid palette(red, light);
}

.legal-footer {
  background-color: palette(gray, xxlight);
  color: palette(gray);
  border-top: 1px solid palette(gray, xlight);
  display: flex;
  flex-wrap: wrap;
  font-size: fontSize(small);
  justify-content: flex-start;
  align-items: center;
  height: $legal-footer-height;
  padding-left: calc($site-spacing-unit / 2);
  padding-right: calc($site-spacing-unit / 2);
  width: 100%;

  .legal-footer-links {
    margin-left: auto;
  }

  a {
    color: palette(gray);
    display: inline-block;
    margin-right: $site-spacing-unit;

    &:hover {
      color: palette(orange);
    }
  }
}

.l-detail-page__sidebar.sidebar {
  overflow-y: auto;
  width: 280px;
}

.react-toast-container {
  z-index: 9999;
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 100px;
  left: 0;
}
