.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-items: flex-end;
  height: 61px;
  background-color: $top-nav-bg-color;
  border-bottom: 1px solid palette(gray, xlight);
  padding-left: $site-spacing-unit;
  padding-right: $site-spacing-unit;

  @include for-phone-only {
    padding-left: calc($site-spacing-unit * 0.25);
    padding-right: calc($site-spacing-unit * 0.25);
  }

  &.top-nav-dark-theme {
    background-color: $top-nav-bg-color-alt;
    color: $top-nav-font-color-alt;
    border-bottom-width: 0;

    .nav-options-link {
      color: $top-nav-font-color-alt;

      &:hover,
      &.nav-options-link--active {
        color: $top-nav-active-font-color-alt;
      }
    }
    .dropdown > .dropdown-toggle > a {
      color: $top-nav-font-color-alt;
    }
  }
  .dropdown > .dropdown-toggle > a {
    color: $top-nav-font-color;
  }
  .top-nav-logo {
    padding-top: 5px;
  }

  .top-nav-links {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    .divider {
      height: 20px;
      width: 2px;
      margin-right: $top-nav-spacing-unit;
      background-color: palette(gray, light);
      @media screen and (max-width: 968px) {
        display: none;
      }
    }
    .expanded {
      background-color: green;
      display: inherit;
    }
    @media screen and (max-width: 968px) {
      border: 1px solid gray;
      display: none;
      flex-direction: column;
      padding: $top-nav-spacing-unit;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: 60px;
      z-index: 100000;
      > a, > div {
        width: 100%;
        margin-right: 0;
      }
    }
    .top-nav-links-text {
      display: block;
      margin: calc($top-nav-spacing-unit / 2) $top-nav-spacing-unit;
    }
  }
  .top-nav-expander {
    display: none;
    @media screen and (max-width: 968px) {
      display: inherit;
    }
  }
  .top-nav-reference-dropdown {
    min-height: 0;
  }
  .top-nav-reference-dropdown-error {
    color: palette(red);
    padding: $site-spacing-unit;
  }
  .top-nav-notification {
    position: relative;
    cursor: pointer;
    text-align: center;
  }

  .top-nav-notification-icon {
    color: palette(white);
  }

  .top-nav-notification-circle {
    @include gradient();
    border-radius: 50%;
    height: 18px;
    min-height: 18px;
    width: 18px;
    min-width: 18px;
  }

  .top-nav-notification-count {
    color: palette(white);
    font-family: fontFamily(code);
    font-size: fontSize(small);
    font-weight: fontWeight(bold);
  }

  .top-nav-notification-count-layout {
    display: none;
    position: absolute;
    right: -10px;
    top: 10px;
  }

  .top-nav-notification--unread {
    .top-nav-notification-count-layout {
      display: block;
    }
  }

  .top-nav-user-initials-dropdown-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $top-nav-height;
  }

  .top-nav-user-initials-circle {
    background-color: palette(gray);
    border-radius: 50%;
    height: 35px;
    min-height: 35px;
    width: 35px;
    min-width: 35px;
  }

  .top-nav-user-initials-text {
    color: palette(white);
    text-transform: uppercase;

    &:empty::before {
      content: '';
      background-image: url('/assets/images/person-light.svg');
      width: 24px;
      height: 24px;
      display: inline-block;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      position: absolute;
      bottom: -18px;
      right: 50%;
      transform: translateX(50%);
      border-bottom-right-radius: 14px 9px;
      border-bottom-left-radius: 14px 9px;
    }
  }
}
