.nav-options {
  display: flex;
}

.nav-options--rigid {
  .nav-options-link {
    white-space: nowrap;
  }
}

.nav-options-link {
  display: block;
  margin-right: $top-nav-spacing-unit;
  color: $top-nav-font-color;
  height: $top-nav-height;
  line-height: calc($top-nav-height + 2px);
  text-transform: uppercase;
  font-weight: fontWeight(semi-bold);
  letter-spacing: 1px;
  font-size: $top-nav-font-size;

  &:hover,
  &.nav-options-link--active {
    cursor: pointer;
    border-bottom: 3px solid palette(orange);
    color: $top-nav-active-font-color;
  }
}
