.dropdown {
  position: relative;

  .dropdown-trigger {
    cursor: pointer;
  }

  .dropdown-arrow-down {
    margin-left: 6px;
    font-size: 14px;
  }

  .dropdown-menu-position {
    display: none;
    position: absolute;
    z-index: $z-dropdown;
    min-width: 220px;
  }

  &.dropdown--open {
    .dropdown-menu-position {
      display: block;
    }
  }

  &.dropdown--drop-west {
    .dropdown-menu-position {
      right: 0;
    }
  }

  &.dropdown--drop-east {
    .dropdown-menu-position {
      left: 100%;
      margin-left: 20px;
      bottom: -31px;
    }
  }

  &.dropdown--drop-south {
    .dropdown-menu-position {
      left: 0;
    }
  }

  .dropdown-menu-style {
    text-transform: none;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: rgba(84, 70, 35, 0.298039) 0px 6px 20px, rgba(84, 70, 35, 0.15) 0px 1px 3px, rgba(0, 0, 0, 0.08) 0px 0px 1px;
  }
}

.dropdown-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 8px 8px;
    border-top: 1px solid color(border);

    &:hover {
      background-color: #f0f0f0;
      transition: background-color 200ms ease-in-out;
    }

    a {
      color: #333;
      display: block;
      padding: 4px 6px;
    }
  }

  .admin-dropdown--active {
    color: palette(orange);
    font-weight: fontWeight(bold);
  }
}

.dropdown-list--alt-theme li a {
  color: $font-color;
  font-weight: fontWeight(semi-bold);
}
