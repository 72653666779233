.action-blocks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  .action-block {
    height: auto;
    max-width: 256px;
    width: 256px;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .action-block-icon {
    }
    h1 {
      font-size: 18px; 
      letter-spacing: 1px;
    }
    p {
      font-size: 14px;
      color: palette(gray);
    }
  }
}
