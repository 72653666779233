.parameters-table {
  @for $i from 1 through 10 {
    .parameters-table-indentation-#{$i} {
      padding-left: calc($i * 7px);
    }
  }
}

.linting-report-table {
  white-space: nowrap;
  width: 100%;
}