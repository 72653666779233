.static-nav {
  margin-bottom: $site-spacing-unit;

  &:last-child {
    margin-bottom: 0;
  }
}

.static-nav--divider-above {
  border-top: 1px solid black;
}

.static-nav-header-1 {
  font-size: 14px;
  font-weight: fontWeight(semi-bold);
  color: palette(gray, xxdark);
  margin-top: $site-spacing-unit;
}

.static-nav-header-2 {
  font-size: 14px;
  color: palette(gray, dark);
  margin-top: 16px;
}

.static-nav-item {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 12px;
  color: palette(gray);

  &:hover {
    color: palette(gray, xdark);
  }

  &.static-nav-item--current {
    font-weight: fontWeight(bold);
    color: palette(orange);
  }
}
