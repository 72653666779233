.top-nav-notifications-dropdown {
  .dropdown-menu-style.dropdown-menu-position {
    right: -65px;
  }

  .top-nav-notifications-list {
    list-style: none;
    width: 415px;

    > li {
      border-bottom: 1px solid color(border);

      &:last-child {
        border-bottom: none;
      }
    }

    .top-nav-notification-list-item {
      padding: 20px 15px 15px 15px;
      text-align: left;

      &.unread {
        background-color: palette(orange, xxlight);
      }

      .top-nav-notification-header {
        display: flex;
        .title-column {
          width: 65%;
          min-width: 65%;
          flex-grow: 1;
          font-size: 14px;
          color: palette(black);
        }
        .date-column {
          text-align: right;
        }
      }

      .summary {
        margin: 10px 0;
        color: palette(gray, dark);
        font-size: 13px;
      }

      > p {
        margin: 5px 0;
        line-height: 15px;
      }

      a {
        font-size: 12px;
        color: palette(orange);
        &:hover {
          text-decoration: none;
        }
      }
    }

    .see-all-announcements {
      display: block;
      font-size: 14px;
      padding: 30px 0;
      background-color: palette(white);
      font-weight: 400;
      font-size: 12px;
      color: palette(orange);
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
