$table-radius: 4px;
$cell-padding: calc($site-spacing-unit / 2);

.table,
.markdown-scope table,
.documentation-style-scope table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 12px;
  border-collapse: separate;  // for rounded corners

  > thead > tr > th {
    padding: $cell-padding;
    border-top: 1px solid color(border);
    border-bottom: 1px solid color(border);
    text-align: left;
    vertical-align: bottom;
    background-color: palette(gray, xxlight);
    color: palette(gray);
    font-weight: fontWeight(semi-bold);

    &:first-child {
      border-left: 1px solid color(border);
      border-top-left-radius: $table-radius;
    }

    &:last-child {
      border-right: 1px solid color(border);
      border-top-right-radius: $table-radius;
    }
  }

  > tbody > tr > td,
  > tfoot > tr > td {
    padding: $cell-padding;
    vertical-align: top;
    border-bottom: 1px solid color(border);

    &:first-child {
      border-left: 1px solid color(border);
    }

    &:last-child {
      border-right: 1px solid color(border);
    }
  }

  > tfoot:last-child > tr:last-child > td,
  > tbody:last-child > tr:last-child > td {
    &:first-child {
      border-bottom-left-radius: $table-radius;
    }

    &:last-child {
      border-bottom-right-radius: $table-radius;
    }
  }
}

.table--sortable {
  > thead > tr > th {
    cursor: pointer;

    .fa {
      color: palette(orange);
    }
  }
}