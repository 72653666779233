.metricsCard {
  background: #FFF;
  border-radius: 6px;
  border: 1px solid #E5E4E7;
}

.metricsInstallColumn {
  position: relative;
}

.metricsInstallWrapper {
  position: absolute;
  height: 100%;
  width: 90%;
  overflow-y: auto;
}
