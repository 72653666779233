.search-box {
  position: relative;
  display: flex;
  border-bottom: 1px solid palette(gray, xlight);
}

.search-box-input {
  width: 100%;
  padding: 10px 25px 10px 15px;
  border: 0;
}

.search-box-button {
  border: 0;
  background-color: palette(white);
}
