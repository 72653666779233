// specific utils (above general utils for easier overrides)
.last-item-right {
  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: auto;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

// General flex box utils
// container properties (parent)
.pct-flex {
  display: flex;
}

.pct-flex--justify-content-end {
  justify-content: flex-end;
}

.pct-flex--align-items-baseline {
  align-items: baseline;
}

.pct-flex--align-items-center {
  align-items: center;
}

// item properties (children)
.pct-flex-item--right {
  margin-left: auto;
}
