.branch-selector {
  padding: space(sm);
  border-top: 1px solid color(border);
}

.branch-selector-help-message {
  border: 1px solid palette(gray, dark);
  background-color: palette(gray, xxlight);
  width: 400px;
  padding: 0px 15px 0px 15px;
  z-index: z-of(attic);
}

.branch-selector-footer [type=checkbox] {
  margin-left: 5px;
}

.branch-selector-current-hover {
  border: 1px solid #000;
  background-color: palette(gray, xxlight);
  padding: 5px;
}
