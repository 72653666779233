.http-response {
}

.http-response-status {
  display: flex;
  align-items: center;
  min-height: 50px;

  & > :first-child {
    width: 70px;
    min-width: 70px;
  }

  & > .http-response-toggle-button {
    margin-left: auto;
    white-space: nowrap;
  }
}

.http-status-digits {
  font-family: fontFamily(code);

  .http-response--success & {
    color: palette(green);
  }
  .http-response--error & {
    color: palette(red, dark);
  }
}

.http-status-text {
  font-weight: 700;
  text-transform: uppercase;
}

.http-status-description {
  font-size: 12px;
  color: #888;
}

.http-response-code {
}
