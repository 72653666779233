.app-cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $site-spacing-unit;

  .empty {
    text-align: center;
    width: 70%;
    margin-left:auto;
    margin-right: auto;
    padding-top: 42px;

    .create-app {
      margin: auto;
      padding-top: 35px;
    }
  }
}

.app-card {
  border-radius: $site-border-radius;
  border: 1px solid color(border);
  color: $font-color;
  height: 120px;
  width: 329px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  position: relative;
  margin-left: calc($site-spacing-unit / 4);
  margin-right: calc($site-spacing-unit / 4);
  margin-bottom: calc($site-spacing-unit / 2);

  .app-card__content {
    padding-right: 16px;
    padding-left: 16px;
    position: relative;
    display: flex;
    align-items: center;
    height: 80%;
  }

  .logo {
    height: 60px;
    width: 60px;

    img {
      height: 60px;
      width: 60px;
      border-radius: 10px;
    }
  }

  .app-info {
    flex-grow: 1;
    width: 100%;
    padding: calc($site-spacing-unit / 2);
    margin-left: 5px;
  }

  .app-card__marketplace-icon {
    opacity: 0.4;
    font-size: 18px;
  }

  .app-card__icon_container {
    text-align: center;
    position: absolute;
    bottom: calc($site-spacing-unit / 2);
    right: calc($site-spacing-unit / 2);

    div {
      padding: 5px 0;
    }
  }

  &:hover {
    box-shadow: 0 0px 0px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.15);
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: calc($site-spacing-unit / 4);
    padding-right: 3rem;
    margin-top: 0;
  }

  .app-card__footer {
    background-color: palette(gray, xxlight);
    border-top: 1px solid color(border);
    bottom: 0;
    font-size: fontSize(small);
    height: 20%;
    padding: 4px;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .app-card__footer--pending {
    @include gradient();
    color: palette(white);
  }
}

.core-react-card__footer {
  border-top: 1px solid palette(gray, xlight);
}
