.global-sitemap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .global-sitemap-column {
    min-width: 200px;
    padding: $site-spacing-unit;
    margin: $site-spacing-unit;
    h1 {
      font-size: 16px;
      text-transform: uppercase;
      color: palette(gray, dark);
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      color: palette(gray);

      li {
        line-height: 27px;
        a {
          color: palette(gray);
          &:hover {
            color: palette(gray, dark);
          }
        }
      }
    }
  }
}
