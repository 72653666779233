.confirmation-email-logo {
  height: 25px;
  object-fit: contain;
  margin-left: 23px;
  margin-top: 11px;
}

.confirmation-email-sent-outer #confirmation-email-sent-flash {
  position: fixed;
  top: 0;
  left: 40%;
  right: 40%;
}

.confirmation-email-sent {
  margin: 15% 30% 15% 30%;
  width: 652px;
  height: 249px;
  object-fit: contain;
  border-radius: 4px;
  background-color: palette(white);
  border: solid 1px #e7e7e7;
  text-align: center;
  padding: 33px 95px 38px 95px;
  max-width: $site-width;
  margin: 230px auto;
  justify-content: center;
}
.confirmation-email-sent-header {
  margin-left:115px;
  margin-right:116px;
  width: 227px;
  height: 24px;
  object-fit: contain;
  font-size: fontSize(large);
  font-weight: fontWeight(bold);
  text-align: center;
  color: #504d4d;
}

.confirmation-email-sent-body {
  width: 480px;
  height: 108px;
  object-fit: contain;
  font-weight: fontWeight(semi-bold);
  text-align: center;
  color: #504d4d;
}

.confirmation-email-sent-body .text-style-1 {
  font-size: fontSize(small);
  color: #676767;
}

.confirmation-email-sent-body .text-style-2 {
  font-size: fontSize(small);
  font-weight: fontWeight(bold);
  color: palette(orange);
}
