.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: none;
  transition: opacity .3s ease;

  &.open {
    display: table;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-height: 95vh;
  overflow: auto;
  width: 750px;
  margin: 0px auto;
  padding: 0 calc($site-spacing-unit * 2);
  text-align: left;
  background-color: palette(white);
  border-radius: 2px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
  transition: all .3s ease;
}

.modal-header {
  display: flex;
  padding: $site-spacing-unit 0;
  align-items: center;

  .modal-close {
    padding: 6px;
    margin-left: auto;
    color: color(muted);
    cursor: pointer;
  }

  h3 {
    font-weight: fontWeight(bold);
    margin: 0;
  }
}

.modal-body {
  margin: calc($site-spacing-unit / 2) 0;
  &.modal-body-no-footer {
    padding-bottom: calc($site-spacing-unit * 2);
  }
}

.modal-footer {
  border-top: 1px solid color(border);
  margin: 0 calc($site-spacing-unit * -2);
  padding: $site-spacing-unit calc($site-spacing-unit * 2);
}
