.partner-signup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: black;
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;

  .partner-signup-info {
    width: 660px;
    color: palette(gray, xxlight);
    padding-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;

    h1 {
      text-transform: uppercase;
    }
    h1,h2,h3,h4,h5,h6 {
      color: palette(white);
    }
    ul {
      li {
        font-size: 16px;
        padding: 16px 0;
      }
    }
  }
  .partner-signup-form {
    background-color: palette(white);
    width: 500px;
    padding: 40px 80px;
  }
}

.integrator-signup {
  width: 463px;
  h1 {
    font-size: 20px;
    text-align: center;
  }
}
